<template>
  <v-card flat>
    <v-card-text>
      <signup-form
        v-if="signup"
        :hide-extra="true"
        @connected="$emit('connected')"
      />
      <login-form
        v-else
        :hide-extra="true"
        @connected="$emit('connected')"
      />
    </v-card-text>
    <div class="py-2">
      <div class="text-center">
        <p
          v-if="signup"
          class="text-center"
        >
          {{ $t("Tu as déjà un compte ?") }} <span
            class="cursor-pointer primary--text text--darken-4 font-600"
            @click.stop="signup = !signup"
          >{{ $t("Se connecter") }}</span>
        </p>
        <p
          v-else
          class="text-center"
        >
          {{ $t("Tu n'as pas de compte PLANETE MARKET ?") }} <span
            class="cursor-pointer primary--text text--darken-4 font-600"
            @click.stop="signup = !signup"
          >{{ $t("S'inscrire") }}</span>
        </p>
      </div>
    </div>
  </v-card>
</template>

<script>

  import SignupForm from './SignupForm.vue'
  import LoginForm from './LoginForm.vue'

  export default {

    directives: {
    },
    components: {
      LoginForm,
      SignupForm,
    },
    props: {
    },
    data: () => ({
      signup: true,
      dialog: false,
    }),
    computed: {

    },
    created: function () {

    },
    destroyed: function () {

    },
    methods: {

    },
  }
</script>
<style scoped>

</style>
